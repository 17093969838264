import React from 'react';
import { useTheme } from './ThemeContext';  // Adjust the import path as necessary
import './ThemeToggle.css';

const ThemeToggle = () => {
    const { theme, toggleTheme } = useTheme();

    return (
        <button id="dark-light-mode-button" onClick={toggleTheme}>
            {theme === 'dark' ? (
                <i className="fas fa-sun" style={{color: "white"}}></i> // Icon for light mode (sun)
            ) : (
                <i className="fas fa-moon"></i> // Icon for dark mode (moon)
            )}
        </button>
    );
};

export default ThemeToggle;
import React from 'react';
import './App.css';
import Navbar from './components/navbar/navbar.js';
import TacticsDropdown from './components/TacticsDropdown/TacticsDropdown.js';
// import ThemeToggle from './components/ThemeToggle.js';
import { SelectedSectionProvider } from './components/SelectedSectionContext';
import { ThemeProvider } from './components/ThemeContext'; // Adjust the path as necessary

function App() {
  return (
    <ThemeProvider>
      <SelectedSectionProvider>
        <div className="App">
            <div className="app-navbar-container">
              <Navbar />
            </div>
            <div className="tactics-dropdown-container">
              <TacticsDropdown /> 
            </div>
        </div>
      </SelectedSectionProvider>
    </ThemeProvider>
  );
}

export default App;

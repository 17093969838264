import React, { createContext, useContext, useState } from 'react';

const SelectedSectionContext = createContext();

export const SelectedSectionProvider = ({ children }) => {
  const [selectedSection, setSelectedSection] = useState('');
  const [isVariableSelected, setIsVariableSelected] = useState(false); // new state

  return (
    <SelectedSectionContext.Provider value={{ selectedSection, setSelectedSection, isVariableSelected, setIsVariableSelected }}>
      {children}
    </SelectedSectionContext.Provider>
  );
};

export const useSelectedSection = () => useContext(SelectedSectionContext);

import React from 'react';
import "./Description.css";
import { useTheme } from '../ThemeContext';
const Description = ({ techniqueDescription, techniqueName, techniqueLink, style }) => {
    const { theme } = useTheme();
    const borderColor = {
      borderColor: theme === 'dark' ? '#4961cb' : '#011985'
    };

    const containerStyle = {
        backgroundColor: theme === 'dark' ? '#212121' : '#fff', // Background color based on theme
        color: theme === 'dark' ? '#fff' : '#212121' // Text color based on theme
    };

    // Function to split the description by newline characters and return an array of paragraph elements
    const renderDescription = (description) => {
        return description.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ));
    };

    return (
      <div id="container" style={containerStyle}>
        {techniqueName && (
          <>
            <div id="description" style={borderColor}>
              <h3 style={{marginTop: "20px"}}>What is {techniqueName}?</h3>
              <div id='description-container'>
                {/* Render the techniqueDescription as paragraphs */}
                {techniqueDescription ? renderDescription(techniqueDescription) : 'No description available.'}
                {/* Check if techniqueLink exists and render it as a clickable link */}
                {techniqueLink && (
                  <a href={techniqueLink} target="_blank" rel="noopener noreferrer">MITRE ATT&CK</a>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
}

export default Description;

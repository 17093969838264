import React from 'react';
import "./GatherData.css";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';

const GatherData = ({ techniqueId, techniqueName }) => {
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'gather-data';
    const { theme } = useTheme();
    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');

    return (
        <div id="container">
            {techniqueName && (
                <>
                <div id="gather-data" style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}`}}>
                <h3 style={{marginTop: "20px"}}>Gather {techniqueName} Data</h3>
                <div className='gather-data-container'>
                    {/* Add a paragraph tag with some default text or additional props if needed */}
                    <p>
The data gathering process in IT security involves the systematic collection of alarms and incidents from various systems within an organization, predominantly managed through Security Information and Event Management (SIEM) software. This software is tailored to each environment, meaning the specifics of data collection can vary widely from one organization to another.

When integrating with third-party Security Orchestration, Automation, and Response (SOAR) systems, this data collection is often enhanced through the use of APIs. These APIs facilitate seamless connections between the SIEM software and the SOAR systems, allowing for efficient transfer of data. This integration is crucial for organizations to efficiently monitor their IT environments and respond to incidents.

The effectiveness of this data gathering process is pivotal in enabling real-time security monitoring and incident response. It ensures that all security data from across the organization's network is captured and analyzed promptly, which is essential for identifying and mitigating potential security threats.</p>
</div>
</div>
                </>
            )}
        </div>
    );
}

export default GatherData;

import React from 'react';

const Welcome = () =>{
    return (
        <div id="welcome">
            <div id="project-description">
                <h1>Welcome to the IRAF prototype!</h1>
                <p>This project was developed as a bachelor project at <strong>Høyskolen Kristiania</strong> in 2024, in collaboration with <strong>Netsecurity</strong>.</p>
                <p>The Incident Response Automation Framework is designed to help in the development of automation of incident response for MITRE ATT&CK Techniques, and comprises eight distinct steps. This website provides detailed guidance on automating both general and technique-specific defensive measures against cyber attacks, drawing significantly from the MITRE D3FEND framework to bridge the gap between MITRE ATT&CK and D3FEND.</p>
            </div>

            <div id="usage-instructions">
                <h4>How to Use:</h4>
                <ol>
                    <li>Select a MITRE ATT&CK Tactic and Technique from the menu on the left.</li>
                    <li>The blue bar at the top displays the eight steps of the framework, from gathering data to reporting. After selecting a technique, use the buttons in the blue bar to explore each phase the automation should contain.</li>
                </ol>
            </div>

            <div id="acknowledgements">
                <h4>Special Thanks:</h4>
                <ul>
                    <li>Erlend Halsnes and Netsecurity</li>
                    <li>Rashmi Gupta at Høyskolen Kristiania</li>
                </ul>
            </div>

            <div id="project-team">
                <h4>Project Team:</h4>
                <ul>
                    <li>Einar Lie</li>
                    <li>Christoffer Hammer</li>
                    <li>Jordan Bracewell</li>
                    <li>Jonas Stensrud</li>
                </ul>
            </div>

        </div>
    );
}

export default Welcome;

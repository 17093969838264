import React from 'react';
import "./MapToTechnique.css";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';

const MapToTechnique = ({ techniqueName, mapToTechnique }) => {
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'map-to-technique';
    const { theme } = useTheme();

    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');
    // Function to render text with URLs as clickable links and split by new lines
    const renderText = (text) => {
        if (!text) return <p>No mapping information available.</p>;

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split('\n').map((paragraph, index) => {
            const parts = paragraph.split(urlRegex); // Split the paragraph by URLs
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        if (part.match(urlRegex)) {
                            return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                        }
                        return part;
                    })}
                </p>
            );
        });
    };

    return (
        <div id="container">
            {techniqueName && (
                <>
                    <div id='map-to-technique' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}` }}>
                    <h3 style={{marginTop: "20px"}}>Map {techniqueName} To MITRE ATT&CK Technique</h3>
                    {/* Render the mapToTechnique text with the renderText function */}
                    <div className='text-container'>{renderText(mapToTechnique)}</div>
                    </div>
                </>
            )}
        </div>
    );
}

export default MapToTechnique;

import React from 'react';
import "./Normalize.css";
import { useTheme } from '../ThemeContext';
import { useSelectedSection } from '../SelectedSectionContext';

const Normalize = ({ techniqueName, normalize}) => {
  const { selectedSection } = useSelectedSection();
  const isActive = selectedSection === 'normalize';
  const { theme } = useTheme();

    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');

    const renderText = (text) => {
        if (!text) return <p>No normalization information available.</p>;

          const urlRegex = /(https?:\/\/[^\s]+)/g;
          return text.split('\n').map((paragraph, index) => {
              const parts = paragraph.split(urlRegex);
              return (
                  <p key={index}>
                      {parts.map((part, i) => {
                          if (part.match(urlRegex)) {
                              return (
                                  <span key={i}>
                                      <a href={part} target="_blank" rel="noopener noreferrer">
                                          <i className="bi bi-link"></i> {part}
                                      </a>
                                  </span>
                              );
                          }
                          return part;
                      })}
                  </p>
              );
        });
    };

    return (
        <div id="container">
            {techniqueName && (
                <>
                    <div id='normalize' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}` }}>
                        <h3 style={{marginTop: "20px"}}>Normalize {techniqueName} data</h3>
                        <div className='normalize-container'>
                            <div>{renderText(normalize)}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
  
export default Normalize;
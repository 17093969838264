import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TacticsDropdown.css";
import Welcome from '../Welcome/Welcome.js';
import Description from '../Description/Description.js';
import GatherData from '../GatherData/GatherData.js';
import Normalize from '../Normalize/Normalize.js';
import MapToTactic from '../MapToTactic/MapToTactic.js';
import MapToTechnique from '../MapToTechnique/MapToTechnique.js';
import Enrich from '../Enrich/Enrich.js';
import Decide from "../Decide/Decide.js";
import Mitigate from "../Mitigate/Mitigate.js";
import Report from "../Report/Report.js";
import Demo from "../Demo/Demo.js";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';

const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const ENDPOINT2 = process.env.REACT_APP_ENDPOINT2;
const ENDPOINT3 = process.env.REACT_APP_ENDPOINT3;
const ENDPOINT4 = process.env.REACT_APP_ENDPOINT4;

const ALLOWED_TACTICS = ["TA0001"];
const ALLOWED_TECHNIQUES = ["T1190", "T1566"]; // Example allowed technique IDs
const ALLOWED_SUBTECHNIQUES = ["T1566.001", "T1566.002"]; // Example allowed sub-technique IDs

function TacticsDropdown() {
  const { theme } = useTheme();
  const [tactics, setTactics] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [subTechniques, setSubTechniques] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openTechnique, setOpenTechnique] = useState(null);
  const contentRef = useRef(null);
  const [dropdownTacticVariable, setDropdownTacticVariable] = useState([]);
  const [dropdownTacticVariableName, setDropdownTacticVariableName] = useState("");
  const { setSelectedSection } = useSelectedSection();

  const sidebarStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : '#f8f9fa', // Conditional background color
    color: theme === 'dark' ? '#fff' : '#212121' // Adjust text color as well
  };

  useEffect(() => {
    fetchTactics();
    fetchTechniques();
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [dropdownTacticVariable]);


  const fetchTactics = async () => {
    try {
      const response = await fetch(`${HOSTNAME}${ENDPOINT2}`);
      const data = await response.json();
      setTactics(data.sort((a, b) => a.id - b.id));
    } catch (error) {
      console.error("Error fetching tactics:", error);
    }
  };

  const fetchTechniques = async () => {
    try {
      const response = await fetch(`${HOSTNAME}${ENDPOINT3}`);
      const data = await response.json();
      setTechniques(data);
    } catch (error) {
      console.error("Error fetching techniques:", error);
    }
  };

  const fetchSubTechniques = async (techniqueId) => {
    try {
        const response = await fetch(`${HOSTNAME}${ENDPOINT4}${techniqueId}`);
        const data = await response.json();
        const filteredSubTechniques = data.filter(subTech => subTech["technique_att&ck_id"] === techniqueId);
        setSubTechniques(filteredSubTechniques);

        return filteredSubTechniques.length > 0;
    } catch (error) {
        console.error("Error fetching sub-techniques:", error);
        return false; // Assume no sub-techniques if an error occurs
    }
};

const buttonClass = theme === 'dark' ? 'btn btn-dark dropdown-toggle' : 'btn btn-light dropdown-toggle';
const buttonClass2 = theme === 'dark' ? 'btn btn-dark' : 'btn btn-light';


const handleTechniqueClick = async (technique) => {
  setSubTechniques([]);
  const techniqueId = technique["att&ck_id"];

  if (openTechnique === techniqueId) {
    setOpenTechnique(null);
  } else {
    setOpenTechnique(techniqueId);
    const hasSubTechniques = await fetchSubTechniques(techniqueId);

    if (!hasSubTechniques) {
      setTimeout(() => setDropdownTacticVariable(technique), 0);
      setDropdownTacticVariableName(technique.name);

      if (contentRef.current) {
        contentRef.current.scrollTo(0, 0);
      }
    }
  }

  // Reset the navbar's selected section on every click
  setSelectedSection('');
};


  useEffect(() => {
    fetchTactics();
    fetchTechniques();
  }, []);

  const toggleDropdown = (index) => {
    setOpenDropdown(prevOpen => (prevOpen === index ? null : index));
  };

  const isAllowedTactic = (tactic) => ALLOWED_TACTICS.includes(tactic["att&ck_id"]);
  const isAllowedTechnique = (technique) => ALLOWED_TECHNIQUES.includes(technique["att&ck_id"]);
  const isAllowedSubTechnique = (subTechnique) => ALLOWED_SUBTECHNIQUES.includes(subTechnique["att&ck_id"]);

  const getButtonClass = (allowed, hasSubTechniques) => {
    if (allowed) {
      return hasSubTechniques ? `${buttonClass2} dropdown-toggle` : buttonClass2;
    }
    return `${buttonClass2} disabled darkened`;
  };
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 sidebar" style={sidebarStyle}>
          <div className="d-flex flex-column align-items-start">
            {tactics.map((tactic, index) => (
              <div key={index}>
                <button 
                  className={buttonClass} 
                  onClick={() => toggleDropdown(index)} 
                  disabled={!isAllowedTactic(tactic)}
                  style={!isAllowedTactic(tactic) ? { opacity: 0.2 } : {}}
                >
                  {tactic["att&ck_id"]} - {tactic.name}
                </button>
                {openDropdown === index && isAllowedTactic(tactic) && (
                  <div>
                    <div className="d-flex flex-column align-items-start pl-3">
                      {techniques.filter(tech => tech["tactic_att&ck_id"] === tactic["att&ck_id"])
                        .map((technique, techIndex) => (
                          <div key={techIndex}>
                            <button 
                              className={getButtonClass(isAllowedTechnique(technique), technique.has_sub_techniques === "yes")}
                              onClick={() => isAllowedTechnique(technique) && handleTechniqueClick(technique, technique.name)}
                              disabled={!isAllowedTechnique(technique)}
                              style={!isAllowedTechnique(technique) ? { opacity: 0.2 } : {}}
                            >
                              {technique.name}
                            </button>
                            {openTechnique === technique["att&ck_id"] && (
                              <div className="d-flex flex-column align-items-start pl-3">
                                {subTechniques.map((subTech, subTechIndex) => (
                                  <div key={subTechIndex}>
                                    <button
                                      className={getButtonClass(isAllowedSubTechnique(subTech), false)}
                                      onClick={() => {
                                        if (isAllowedSubTechnique(subTech)) {
                                          setDropdownTacticVariable(subTech);
                                          setDropdownTacticVariableName(subTech.name);
                                          setSelectedSection('');
                                        }
                                      }}
                                      disabled={!isAllowedSubTechnique(subTech)}
                                      style={!isAllowedSubTechnique(subTech) ? { opacity: 0.2 } : {}}
                                    >
                                      {subTech.name}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-9 content" ref={contentRef}>
          {Object.keys(dropdownTacticVariable).length === 0 ? (
            <Welcome />
          ) : (
            <>
              <Description 
                techniqueDescription={dropdownTacticVariable.description} 
                techniqueName={dropdownTacticVariableName} 
                techniqueLink={dropdownTacticVariable.description_link}
              />
              <GatherData 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName}
              />
              <Normalize 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName}
                normalize={dropdownTacticVariable.normalize}
              />
              <MapToTactic 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName}
                mapToTactic={dropdownTacticVariable.map_to_tactic}
              />
              <MapToTechnique 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName} 
                mapToTechnique={dropdownTacticVariable.map_to_technique}
              />
              <Enrich 
                key={dropdownTacticVariable ? dropdownTacticVariable["_id"] || dropdownTacticVariable["att&ck_id"] : "none"}
                enrich={dropdownTacticVariable && dropdownTacticVariable.d3fend_techniques}
                techniqueName={dropdownTacticVariableName} 
              />
              <Decide 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName} 
                decide={dropdownTacticVariable.decide}
              />
              <Mitigate 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName} 
                mitigate={dropdownTacticVariable.mitigate}
              />
              <Report 
                techniqueId={dropdownTacticVariable} 
                techniqueName={dropdownTacticVariableName} 
                report={dropdownTacticVariable.report}
              />
              <Demo
                svgContent={dropdownTacticVariable.svg} 
                techniqueName={dropdownTacticVariableName} 
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TacticsDropdown;

import React from 'react';
import { useSelectedSection } from '../SelectedSectionContext';
import './navbar.css';
import lightLogo from '../../images/iraflogotrans.png'; // Light theme logo
import darkLogo from '../../images/iraflogotrans-dark.png'; // Dark theme logo
import { useTheme } from '../ThemeContext'; //
import ThemeToggle from '../ThemeToggle.js';

const Navbar = () => {
  // State to track the currently selected section
  const { selectedSection, setSelectedSection } = useSelectedSection();
  const validSections = ['gather-data', 'normalize', 'map-to-tactic', 'map-to-technique', 'enrich', 'decide', 'mitigate', 'report', 'demo'];
  const { theme } = useTheme();

  console.log(selectedSection);

  const navbarStyle = {
    backgroundColor: theme === 'dark' ? '#212529' : '#f8f9fa',
    color: theme === 'dark' ? '#fff' : '#212121',
    transition: 'background-color 0.3s, color 0.3s'
};

const logo = theme === 'dark' ? darkLogo : lightLogo;

  // Function to scroll to a section and update the selected section state
  const scrollToSection = (sectionId) => {
    setSelectedSection(sectionId); // Update the selected section
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // Function to generate class name based on selection
  const getClassName = (sectionId) => {
    const isSelected = validSections.includes(selectedSection) && selectedSection === sectionId;
    const extraClass2 = sectionId === 'gather-data' ? 'gather-data-button' : '';
    // Add a specific class for 'demo'
    const extraClass = sectionId === 'demo' ? 'demo-button' : '';
    return `nav-button ${isSelected ? 'selected' : ''} ${extraClass} ${extraClass2}`;
  };
  

  return (
    <div id='navbar' className="navbar-container" style={navbarStyle}>
      <div className="logo-container">
      <button className="theme-toggle-btn">
                <ThemeToggle/>
            </button>
          <a href="/">
            <img src={logo} alt="IRAF Logo" className="iraf-logo"/>
          </a>
      </div>
      <ul className="navbar">
        <li className={getClassName('gather-data')} onClick={() => scrollToSection('gather-data')}>Gather Data</li>
        <li className={getClassName('normalize')} onClick={() => scrollToSection('normalize')}><p className='text'>Normalize</p></li>
        <li className={getClassName('map-to-tactic')} onClick={() => scrollToSection('map-to-tactic')}><p className='text'>Map to Tactic</p></li>
        <li className={getClassName('map-to-technique')} onClick={() => scrollToSection('map-to-technique')}><p className='text'>Map to Technique</p></li>
        <li className={getClassName('enrich')} onClick={() => scrollToSection('enrich')}><p className='text'>Enrich & Analyze</p></li>
        <li className={getClassName('decide')} onClick={() => scrollToSection('decide')}><p className='text'>Decide</p></li>
        <li className={getClassName('mitigate')} onClick={() => scrollToSection('mitigate')}><p className='text'>Mitigate</p></li>
        <li className={getClassName('report')} onClick={() => scrollToSection('report')}><p className='text'>Report</p></li>
        <li className={getClassName('demo')} onClick={() => scrollToSection('demo')}><p className='text'>Visualization</p></li>
      </ul>
    </div>
  );
};

export default Navbar;

import React from 'react';
import "./Decide.css";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';

const Decide = ({ techniqueId, techniqueName, decide, style }) => {
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'decide';
    const { theme } = useTheme();
    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');
    // Function to render text with URLs as clickable links and split by new lines
    const renderText = (text) => {
        if (!text) return <p>No decision information available.</p>;

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split('\n').map((paragraph, index) => {
            const parts = paragraph.split(urlRegex); // Split the paragraph by URLs
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        if (part.match(urlRegex)) {
                            return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                        }
                        return part;
                    })}
                </p>
            );
        });
    };

    return (
        <div id="container">
            {techniqueName && (
                <>
                <div id='decide' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}`}}>
                    <h3 style={{marginTop: "20px"}}>Decide</h3>
                    <div className='decide-container'>
                        {/* Render the decideText with the renderText function */}
                        <div>{renderText(decide)}</div>
                    </div>
                </div>
                </>
            )}
        </div>
    );
}

export default Decide;

import React, { useState, useEffect } from 'react';
import "./Enrich.css";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';
const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const ENDPOINT1 = process.env.REACT_APP_ENDPOINT1;

const Enrich = ({ enrich, techniqueName, style }) => {
    const [d3fendTechniques, setD3fendTechniques] = useState([]);
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'enrich';
    const { theme } = useTheme();

    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');

    const containerStyle = {
        backgroundColor: theme === 'dark' ? '#212121' : '#fff', // Background color based on theme
        color: theme === 'dark' ? '#fff' : '#212121' // Text color based on theme
    };

    useEffect(() => {
        const fetchD3fendTechniques = async () => {
            try {
                const response = await fetch(`${HOSTNAME}${ENDPOINT1}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // Filter the data to only include techniques with an ID in the enrich array
                const filteredData = data.filter(technique => enrich.includes(technique.d3fend_id));
                setD3fendTechniques(filteredData);
            } catch (error) {
                console.error("Failed to fetch d3fend techniques:", error);
            }
        };

        fetchD3fendTechniques();
    }, [enrich]); // Depend on enrich so the effect runs when enrich changes

    // Function to split the description by newline characters and return an array of paragraph elements
    const renderText = (text) => {
        if (!text) return <p>No information available.</p>;
    
        // Regex to detect URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;
    
        return text.split('\n').map((paragraph, index) => {
            const parts = paragraph.split(urlRegex); // Split the paragraph by URLs
    
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        // Check if the part is a URL
                        if (part.match(urlRegex)) {
                            return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
                        }
                        return part;
                    })}
                </p>
            );
        });
    };
    

    return (
        <div id="container" style={containerStyle}>
            {techniqueName && (
                <>
                    <div id='enrich' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}`}}>

                        <h3 id='heading' style={{marginTop: "20px"}}>Enrich & Analyze {techniqueName}</h3>
                        <div className='enrich-container'>
                        {d3fendTechniques.map((technique) => (
                            <div className="description-container" key={technique.d3fend_id}>
                                <h4>{technique.name}</h4>
                                {/* Render the technique definition as paragraphs */}
                                <div className='definition-box'>{technique.definition}</div>
                                {/* Render the technique text as paragraphs */}
                                <div>{renderText(technique.text)}</div>
                            </div>
                        ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Enrich;

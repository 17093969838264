import React from 'react';
import "./Report.css";
import { useTheme } from '../ThemeContext';
import { useSelectedSection } from '../SelectedSectionContext';

const Report = ({ techniqueName, report }) => {
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'report';
    const { theme } = useTheme();

    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');
    // Function to render text with URLs as clickable links and split by new lines
    const renderText = (text) => {
        if (!text) return <p>No report information available.</p>;

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.split('\n').map((paragraph, index) => {
            const parts = paragraph.split(urlRegex);
            return (
                <p key={index}>
                    {parts.map((part, i) => {
                        if (part.match(urlRegex)) {
                            return (
                                <span key={i}>
                                    <a href={part} target="_blank" rel="noopener noreferrer">
                                        <i className="bi bi-link"></i> {part}
                                    </a>
                                </span>
                            );
                        }
                        return part;
                    })}
                </p>
            );
        });
    };

    return (
        <div id="container">
            {techniqueName && (
                <>
                    <div id='report' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}` }}>
                        <h3 style={{marginTop: "20px"}}>Report</h3>
                        <div className='report-container'>
                            {/* Render the reportText with the renderText function */}
                            <div>{renderText(report)}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Report;

import React from 'react';
import "./Demo.css";
import { useSelectedSection } from '../SelectedSectionContext';
import { useTheme } from '../ThemeContext';

const Demo = ({ techniqueName, svgContent }) => {
    const { selectedSection } = useSelectedSection();
    const isActive = selectedSection === 'demo';
    const { theme } = useTheme();
    const borderColor = isActive ? (theme === 'dark' ? '#011985' : '#011985') : (theme === 'dark' ? '#4961cb' : '#011985');
    return (
        <div id="container">
            {techniqueName && (
                <>
                    <div id='demo' style={{ border: isActive ? '3px solid #DC3545' : `3px solid ${borderColor}`}}>
                    <h3 style={{marginTop: "20px"}}>{techniqueName} Visualization</h3>
                    <div className='demo-container'>
                        <div id="visualization" dangerouslySetInnerHTML={{ __html: svgContent }} />
                    </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Demo;
